import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import Link from 'next/link';

// Utils
import { cn } from 'utils';

// Styles
import styles from './SearchResultList.module.css';

// Hooks
import useResponsiveQueries from '../../../src/hooks/useResponsiveQueries';

// Constants
import { URL_SEARCH_PAGES } from '../../../src/constants';

function SearchResultList({
  label, emptyListLabel, list, keyProp, theme,
}) {
  const { isMobile } = useResponsiveQueries();
  const { locale } = useRouter();

  const createSearchPageLink = (canonicalSlug) => {
    const base = URL_SEARCH_PAGES[locale];
    const cleanedSlug = canonicalSlug.replace(`${locale}-`, '');

    return `/${base}/${cleanedSlug}`;
  };

  return (
    <div className={cn([styles.container, styles[theme]])}>
      <span className={styles.label}>{label}</span>
      <ul className={styles.list}>
        <AnimatePresence initial={false}>
          {list?.length ? list.map((item) => (
            <motion.li
              key={item?.canonicalSlug}
              className={styles.item}
              initial={{
                opacity: 0,
                height: 0,
                transform: isMobile ? 'translateX(16px)' : 'translateY(30px)',
              }}
              animate={{
                opacity: 1,
                height: 'auto',
                transform: isMobile ? 'translateX(0)' : 'translateY(0)',
              }}
              exit={{
                opacity: 0,
                height: 0,
                transform: isMobile ? 'translateX(16px)' : 'translateY(20px)',
              }}
              transition={{ duration: 0.2 }}
            >
              <Link className={styles.itemContent} href={createSearchPageLink(item?.canonicalSlug)}>
                {item?.data?.[keyProp]?.[0]?.data?.[[keyProp]]}
              </Link>
            </motion.li>
          )) : (
            <motion.li
              className={styles.item}
              initial={{
                opacity: 0,
                height: 0,
                transform: isMobile ? 'translateX(16px)' : 'translateY(30px)',
              }}
              animate={{
                opacity: 1,
                height: 'auto',
                transform: isMobile ? 'translateX(0)' : 'translateY(0)',
              }}
              exit={{
                opacity: 0,
                height: 0,
                transform: isMobile ? 'translateX(16px)' : 'translateY(20px)',
              }}
              transition={{ duration: 0.2 }}
            >
              <span className={styles.itemContent}>{emptyListLabel}</span>
            </motion.li>
          )}
        </AnimatePresence>
      </ul>
    </div>
  );
}

SearchResultList.propTypes = {
  label: PropTypes.string,
  emptyListLabel: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        city: PropTypes.arrayOf(PropTypes.shape({})),
        region: PropTypes.arrayOf(PropTypes.shape({})),
        department: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  ),
  keyProp: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
};

SearchResultList.defaultProps = {
  label: '',
  emptyListLabel: '',
  list: [],
  keyProp: '',
  theme: 'dark',
};

export default SearchResultList;
