/* eslint-disable import/prefer-default-export */
import { useQuery } from '@tanstack/react-query';

// Client
import client from './cmsClient';

/**
 * Get content translations
 * @param {Object} params
 * @param {string} params.pageNumber
 * @param {string} params.pageSize
 * @param {string} params.contentId
 * @param {string} params.contentTypeId
 * @param {string} params.referencingContentId
 * @param {string} params.search
 * @param {string} params.slug
 * @param {string} locale
 */
export const getContentTranslation = (params, locale) => client.get('/cms/public/content-translations', {
  params: {
    pageNumber: 1,
    pageSize: 10,
    ...params,
  },
  headers: {
    'Accept-Language': locale,
  },
}).then((res) => res?.data);

/**
 * Get content translations with the Proxy call API
 * @param {Object} params
 * @param {number} params.pageNumber
 * @param {number} params.pageSize
 * @param {string} params.contentId
 * @param {string} params.contentTypeId
 * @param {number} params.depth
 * @param {bool} params.preview
 * @param {string} params.referencingContentId
 * @param {string} params.search
 * @param {string} params.slug
 * @param {string} locale
 * @param {string} bearerToken
 */
export const getContentTranslationProxy = (params, locale, bearerToken) => client.get('/cms-backend/content-translations', {
  params: {
    pageNumber: 1,
    pageSize: 10,
    depth: 3,
    preview: null,
    ...params,
  },
  headers: {
    'Accept-Language': locale,
    ...bearerToken ? { 'X-Authorization': `Bearer ${bearerToken}` } : {},
  },
}).then((res) => res?.data);

export const QUERY_KEY_CONTENT_TRANSLATION = 'page';

export const useContentTranslation = (params, locale, options) => useQuery(
  [QUERY_KEY_CONTENT_TRANSLATION, { ...params, locale }],
  () => getContentTranslationProxy(params, locale),
  { ...options },
);

// export function for server side call
